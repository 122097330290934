export interface IUniversalScreenerStatusModel {
  startDate: string;
  endDate: string;
  status: EUniversalScreenerStatus;
}

export enum EUniversalScreenerStatus {
  COMPLETE = 'Complete',
  NO_CURRENT_SCHOOL_YEAR = 'No Current School Year',
  NO_TEST_FOUND = 'No Test Found',
}
